<template>
  <div class="container">
      <CRow>
          <CCol col="12" xl="6" class="weather_img_col">
              <!-- <img src="{1}" alt="" style="width: 180px; height: 100px; border-radius: 10px; border: 2px solid gray;" />-->
              <img class="weather_img" :src="weather_image" alt="날씨">
          </CCol>
  
          <CCol col="12" xl="6" class="weather_contents">
              <div class="wrapper">
                  <CRow>
                      <!-- 온도 -->
                      <!-- <i class="fa-solid fa-temperature-three-quarters fa-2x" id="weather_icon"></i> -->
                      <!-- <i class="fa-solid fa-temperature-high fa-2x" id="weather_icon"></i> -->
                      <div class="contentWrapper">
                        <svg xmlns="http://www.w3.org/2000/svg" height="22" width="22" viewBox="0 0 512 512"><!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2023 Fonticons, Inc.--><path fill="#6b7994" d="M416 64a32 32 0 1 1 0 64 32 32 0 1 1 0-64zm0 128A96 96 0 1 0 416 0a96 96 0 1 0 0 192zM96 112c0-26.5 21.5-48 48-48s48 21.5 48 48V276.5c0 17.3 7.1 31.9 15.3 42.5C217.8 332.6 224 349.5 224 368c0 44.2-35.8 80-80 80s-80-35.8-80-80c0-18.5 6.2-35.4 16.7-48.9C88.9 308.4 96 293.8 96 276.5V112zM144 0C82.1 0 32 50.2 32 112V276.5c0 .1-.1 .3-.2 .6c-.2 .6-.8 1.6-1.7 2.8C11.2 304.2 0 334.8 0 368c0 79.5 64.5 144 144 144s144-64.5 144-144c0-33.2-11.2-63.8-30.1-88.1c-.9-1.2-1.5-2.2-1.7-2.8c-.1-.3-.2-.5-.2-.6V112C256 50.2 205.9 0 144 0zm0 416c26.5 0 48-21.5 48-48c0-20.9-13.4-38.7-32-45.3V112c0-8.8-7.2-16-16-16s-16 7.2-16 16V322.7c-18.6 6.6-32 24.4-32 45.3c0 26.5 21.5 48 48 48z"/></svg>
                      </div>
                      <div class="data">{{ siteWeather.T1H }} ℃</div>
                  </CRow>
              </div>
              <div class="wrapper">
                  <CRow>
                      <!-- 습도 -->
                      <!-- <i class="fas fa-tint fa-2x" id="weather_icon" ></i> -->
                      <div class="contentWrapper">
                        <svg xmlns="http://www.w3.org/2000/svg" height="22" width="22" viewBox="0 0 512 512"><!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2023 Fonticons, Inc.--><path fill="#707a89" d="M192 512C86 512 0 426 0 320C0 228.8 130.2 57.7 166.6 11.7C172.6 4.2 181.5 0 191.1 0h1.8c9.6 0 18.5 4.2 24.5 11.7C253.8 57.7 384 228.8 384 320c0 106-86 192-192 192zM96 336c0-8.8-7.2-16-16-16s-16 7.2-16 16c0 61.9 50.1 112 112 112c8.8 0 16-7.2 16-16s-7.2-16-16-16c-44.2 0-80-35.8-80-80z"/></svg>
                      </div>
                      <div class="data"> {{ siteWeather.REH  }} %</div> 
                  </CRow>
              </div>
              <div class="wrapper">
                  <CRow>
                      <!-- 바람 -->
                      <!-- <i class="fas fa-wind fa-2x" id="weather_icon" ></i> -->
                      <div class="contentWrapper">
                        <svg xmlns="http://www.w3.org/2000/svg" height="22" width="22" viewBox="0 0 512 512"><!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2023 Fonticons, Inc.--><path fill="#707a89" d="M288 32c0 17.7 14.3 32 32 32h32c17.7 0 32 14.3 32 32s-14.3 32-32 32H32c-17.7 0-32 14.3-32 32s14.3 32 32 32H352c53 0 96-43 96-96s-43-96-96-96H320c-17.7 0-32 14.3-32 32zm64 352c0 17.7 14.3 32 32 32h32c53 0 96-43 96-96s-43-96-96-96H32c-17.7 0-32 14.3-32 32s14.3 32 32 32H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H384c-17.7 0-32 14.3-32 32zM128 512h32c53 0 96-43 96-96s-43-96-96-96H32c-17.7 0-32 14.3-32 32s14.3 32 32 32H160c17.7 0 32 14.3 32 32s-14.3 32-32 32H128c-17.7 0-32 14.3-32 32s14.3 32 32 32z"/></svg>
                      </div>
                      <div class="data">{{ siteWeather.WSD  }} m/s</div> 
                      </CRow>
              </div>
              <div class="wrapper">
                  <CRow>
                      <!-- 강수량 -->    
                      <!-- <i class="fas fa-umbrella fa-2x" id="weather_icon" ></i> -->
                      <div class="contentWrapper">
                        <svg xmlns="http://www.w3.org/2000/svg" height="22" width="22" viewBox="0 0 576 512" ><!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2023 Fonticons, Inc.--><path fill="#707a89" d="M288 0c17.7 0 32 14.3 32 32V49.7C451.8 63.4 557.7 161 573.9 285.9c2 15.6-17.3 24.4-27.8 12.7C532.1 283 504.8 272 480 272c-38.7 0-71 27.5-78.4 64.1c-1.7 8.7-8.7 15.9-17.6 15.9s-15.8-7.2-17.6-15.9C359 299.5 326.7 272 288 272s-71 27.5-78.4 64.1c-1.7 8.7-8.7 15.9-17.6 15.9s-15.8-7.2-17.6-15.9C167 299.5 134.7 272 96 272c-24.8 0-52.1 11-66.1 26.7C19.4 310.4 .1 301.5 2.1 285.9C18.3 161 124.2 63.4 256 49.7V32c0-17.7 14.3-32 32-32zm0 304c12.3 0 23.5 4.6 32 12.2V430.6c0 45-36.5 81.4-81.4 81.4c-30.8 0-59-17.4-72.8-45l-2.3-4.7c-7.9-15.8-1.5-35 14.3-42.9s35-1.5 42.9 14.3l2.3 4.7c3 5.9 9 9.6 15.6 9.6c9.6 0 17.4-7.8 17.4-17.4V316.2c8.5-7.6 19.7-12.2 32-12.2z"/></svg>
                      </div>
                      <div class="data" id="wrapper_data">{{ siteWeather.RN1 }} mm</div> 
                  </CRow>
              </div>
          </CCol>       
      </CRow>
      <CCol col="12" xl="12" class="location_contents" >
          <div class="data" id="location_data"> <i class="fas fa-map-marker-alt fa-2x" id="location_icon"></i> <span id="location_addr">″ {{ address_ }} ″</span></div>
          <div class="wrapper">
            <div class="data" id="time_data"> {{ siteWeather.ncstTime}}(※ 기상청 데이터)</div>
          </div>
      </CCol>
  
  </div>  
</template>

<script>
import loopback from '@/services/loopback';
import moment from 'moment';
import utils from '@/services/utils';

export default {
  name: 'Weather',
  components: {
  },
  props: {
    prefix: {
      type: String,
      default: ''
    }
  },
  created: function() {
    this.userInfo = this.$store.state.auth.user_info;
    this.user = this.userInfo.user;
    this.sites = this.userInfo.sites

    this.active_site_index = _.get(this.user, "active_site_index", 0);
    //this.active_site_name = this.user.active_site_name;

    this.getWeatherData(this.active_site_index, this.user.active_site_name);
  },
  data () {
    return {
      active_site_index: 0,
      siteWeather: {},
      weather_image: 'images/cloud.png',
      address_ : ""
    }
  },
  methods: {
    getWeatherData(index, address) {
      var self = this;
      var default_site_guid = _.get(this.user, "default_site_guid");
      this.address_ = address;
      var data = {
        from: moment(new Date().setMinutes(0, 0, 0)).subtract(1,'day'),
        to: moment(new Date().setMinutes(0, 0, 0)).add(9,'hour'),
        site_guid: _.isEmpty(this.user.active_site_guids[index]) ? default_site_guid : this.user.active_site_guids[index]        
      }
      return loopback
        .method('weather', 'getWeatherStat', data)
        .then(res => {
          if(res.data.length == 0){
            console.log("load default data none");
          }
          else{
            res.data[0].VEC = utils.getWindDirectionName(Number(res.data[0].VEC))
            self.siteWeather = res.data[0];
            self.siteWeather.ncstTime = self.parseFcstTime(self.siteWeather.ncstTime);
            self.weather_image = this.getWeatherImage(self.siteWeather.PTY);
          }
          return Promise.resolve(self.siteWeather)
        })
    }, 
    parseFcstTime(time) {
      if(!/^(\d){12}$/.test(time)) return "Invalid Date";
      var dt = time.replace(/^(\d{4})(\d\d)(\d\d)(\d\d)(\d\d)$/, '$1-$2-$3 $4:$5');
      return moment(new Date(dt)).format("MM월 DD일 HH시 예보");
    },
    getWeatherImage(pty) {
      var image_ = 'images/cloud.png';
      switch(pty){
        case '0':
          image_ = 'images/sun.png';
          break;
        case '1':
          image_ = 'images/rainy.png';
          break;
        case '2':
          image_ = 'images/cloudy_rain.png';
          break;
        case '3':
          image_ = 'images/snowy.png';
          break;
        case '4':
          image_ = 'images/rainy.png';
          break;
        case '5':
          image_ = 'images/hail.png';
          break;
        case '6':
          image_ = 'images/snowy.png';
          break;
        case '7':
          image_ = 'images/snowy.png';
          break;                                          
      }      
      return image_;
    }
  }
}
</script>


<style lang="scss" scoped>
.container {
    height: 220px;
    padding: 2%;
    font-weight: 800;
    font-size: 14px;
    display: flex;
    flex-wrap: wrap;
    transition: box-shadow .3s;
    justify-content: space-around;
    align-items: center;
    &:hover {
        box-shadow: 0 0 11px rgba(33,33,33,.2); 
    }
    border-radius: 14px;
    > * {
        display: flex;
        align-items: center;
    }
    .wrapper-tm {
        font-size: 13px;
        width: 130px;
    }
    .wrapper {
      margin-bottom: 5px;
      width: 90%;
    }
    .data {
        margin-left: 5px;
    }
}

.contentWrapper {
  margin-right: 2%;
}

.weather_img{
    max-width: 65%;
    height: 70%;
    margin-left: 25%;
}
.weather_img_col {
    border-right: 1px solid #ccc;
}

.weather_contents{
    left: 3%;
    width: 40%;
}
.location_contents{
    align-items: baseline;
    display: contents;
}

#location_icon{
    color: #FF6969;
    
}
#location_data{
    font-size: 1em;
}

#location_addr{
    position: relative;
    top:-0.4vh;
}

#weather_icon{
    color: #bbbbbb;
    width: 14%;
    padding: 1% 3% 1% 3%;
    margin-right: 11%;
    margin-bottom: 1.5%;
    font-size: 20px;
}

#wrapper_data {
  margin-left: 10px;
}

#time_data{
    color: grey;
    margin-left:4%;
    font-size: 0.9em;
    text-align: center;
}
@media (max-width: 1800px) {
    #location_data{
        font-size: 0.8em;
    }
    #time_data{
        font-size: 0.7em;
    }
}

@media (max-width: 1200px) {
.container {
    height: 50px;
    display: none;
}
}
</style>